import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import settings from '../../settings';
import api from '../logic/api';
import Alert from './Alert';

const Feedback = ({ closePopup }) => {
  const location = useLocation();
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);
  const [error, setError] = useState('');
  const [feedBackContainer, setFeedBackContainer] = useState({
    title: '',
    user: '',
    date: '',
    time: '',
    description: '',
    expected: '',
    browserDetails: {
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform
        ? window.navigator.platform
        : 'undefined',
      languages: window.navigator.languages,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      plugins: window.navigator.plugins
        ? window.navigator.plugins
        : 'undefined',
      cookieEnabled: window.navigator.cookieEnabled,
      pdfViewerEnabled: window.navigator.pdfViewerEnabled,
    },
    location: location.pathname,
    userdata: JSON.parse(localStorage.getItem('userdata')),
  });

  const sendFeedback = async (event) => {
    event.preventDefault();
    setLoginButtonLoading(true);

    api('post', settings.general.sendFeedback, {
      dcFeedback: JSON.stringify(feedBackContainer),
    })
      .then((res) => {
        if (res) {
          setLoginButtonLoading(false);
          swal({
            title: 'Feedback gesendet',
            text: 'Vielen Dank für Ihr Feedback!',
            icon: 'success',
          });
          closePopup();
        } else {
          setError(res.message);
          setLoginButtonLoading(false);
        }
      })
      .catch((error) => {
        console.log('Error:', error);
        swal({
          title: 'Fehler',
          text: 'Fehler beim Senden des Feedbacks',
          icon: 'error',
        });
        setLoginButtonLoading(false);
      });
  };

  return (
    <div className="Feedback-form-pop-room">
      {error && (
        <Alert type="error" title="Fehler" onDismiss={() => setError('')}>
          {error}
        </Alert>
      )}
      <div className="Feedback-form-pop">
        <h2> Feedback Senden </h2>
        <p>
          Hier können Sie uns Feedback geben oder ein Problem melden. Wir werden
          uns so schnell wie möglich darum kümmern.
        </p>
        <form>
          <div className="Login-Form radius">
            <label htmlFor="Titel" className="Feedback-Form-Pop-email-label">
              <input
                type="text"
                name="title"
                id="Titel"
                className="Feedback-Form-Pop-email-input"
                onChange={(e) => {
                  setFeedBackContainer({
                    ...feedBackContainer,
                    title: e.target.value,
                  });
                }}
              />
              <span>Titel</span>
            </label>
            <label htmlFor="User" className="Feedback-Form-Pop-email-label">
              <input
                type="text"
                name="user"
                id="User"
                className="Feedback-Form-Pop-email-input"
                onChange={(e) => {
                  setFeedBackContainer({
                    ...feedBackContainer,
                    user: e.target.value,
                  });
                }}
              />
              <span>Nutzer</span>
            </label>
            <label htmlFor="Date" className="Feedback-Form-Pop-email-label">
              <input
                type="date"
                name="date"
                id="Date"
                className="Feedback-Form-Pop-email-input"
                onChange={(e) => {
                  setFeedBackContainer({
                    ...feedBackContainer,
                    date: e.target.value,
                  });
                }}
              />
              <span>Datum</span>
            </label>
            <label htmlFor="Time" className="Feedback-Form-Pop-email-label">
              <input
                type="time"
                name="time"
                id="Time"
                placeholder="Uhrzeit"
                className="Feedback-Form-Pop-email-input"
                onChange={(e) => {
                  setFeedBackContainer({
                    ...feedBackContainer,
                    time: e.target.value,
                  });
                }}
              />
              <span>Uhrzeit</span>
            </label>
            <div>
              <label className="Feedback-Form-Pop-email-label">
                <textarea
                  id="Accepted"
                  className="Feedback-Form-Pop-order-notes-textarea"
                  rows="4"
                  onChange={(e) => {
                    setFeedBackContainer({
                      ...feedBackContainer,
                      description: e.target.value,
                    });
                  }}
                />
                <span>
                  Beschreiben Sie Ihr Problem oder geben Sie uns Feedback:
                </span>
              </label>
            </div>
            <div>
              <label className="Feedback-Form-Pop-email-label">
                <textarea
                  id="Accepted"
                  name="accepted"
                  className="Feedback-Form-Pop-order-notes-textarea"
                  rows="4"
                  onChange={(e) => {
                    setFeedBackContainer({
                      ...feedBackContainer,
                      expected: e.target.value,
                    });
                  }}
                />
                <span>Erwartetes Ergebnis:</span>
              </label>
            </div>
            <div className="Feedback-Form-Pop-Button-Area">
              <button
                className="Feedback-Form-Pop-Cancel"
                onClick={closePopup}
                disabled={!!loginButtonLoading}
                name="login"
                type="submit"
              >
                Abbrechen
              </button>
              <button
                className="Feedback-Form-Pop-Submit"
                onClick={sendFeedback}
                disabled={!!loginButtonLoading}
                name="login"
                type="submit"
              >
                {loginButtonLoading ? (
                  <div className="LoginLoader">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  <>Feedback senden</>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Feedback;
