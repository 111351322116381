import { faEdit, faRepeat } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import settings from '../../../../settings';
import HeaderbarButton from '../../../components/HeaderbarButton';
import Loader from '../../../components/Loader';
import api from '../../../logic/api';
import getConstants from '../../../logic/constants';
import DataContainer from '../../../logic/dataContainer';
import SearchDropdown from '../../../components/SearchDropdown';
import history from '../../../history';

class OverviewResubmission extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);

    this.state = {
      loading: true,
      data: [],
      CarePsych: [],
      PlaceOfConsultation: [],
      CarePsychQuery: params.get('status')?.toLowerCase() || '',
      PlaceOfConsultationQuery: params.get('place')?.toLowerCase() || '',
    };
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      CarePsych: await getConstants('CarePsych'),
      PlaceOfConsultation: await getConstants('PlaceOfConsultation'),
    });
  }

  loadAppointments = () => {
    this.setState({
      loading: true,
    });
    const dataColumns = {
      Typ: 'Appointment',
    };
    api('get', settings.clientmanagement.dataContainer.getList, {
      parameter: JSON.stringify(dataColumns),
    })
      .then((res) => {
        const filteredClientData = new DataContainer(res);
        this.setState({
          data: filteredClientData.getMany('Entry'),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.loadAppointments();
  }

  setPath = (status, place) => {
    const baseURI = this.props.match.path.endsWith('search')
      ? this.props.match.path.substring(0, this.props.match.path.length - 7)
      : this.props.match.path.substring(0, this.props.match.path.length - 1);

    // Get the current search parameters from the URL
    const currentSearchParams = new URLSearchParams();
    if (status.length > 0) currentSearchParams.set('status', status);
    if (place.length > 0) currentSearchParams.set('place', place);

    const newPath = `${baseURI}/search?${currentSearchParams.toString()}`;

    history.replace(newPath);
  };

  filter = (key, value) => {
    const newValue = !!value ? value.toLowerCase() : '';
    this.setState({ [key]: newValue }, () => {
      if (key === 'CarePsychQuery') {
        this.setPath(newValue, this.state.PlaceOfConsultationQuery);
      }
      if (key === 'PlaceOfConsultationQuery') {
        this.setPath(this.state.CarePsychQuery, newValue);
      }
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loader text="Termine werden geladen..." />
        </div>
      );
    }

    let data = [];
    if (this.state.data.length) {
      data = this.state.data;
      data = data.filter((elem) => {
        if (this.state.CarePsychQuery.length > 0) {
          console.log(' elem: ', elem.CarePsych.toLowerCase());
          console.log(
            ' this.state.CarePsychQuery: ',
            this.state.CarePsychQuery,
          );
          return elem.CarePsych.toLowerCase() === this.state.CarePsychQuery;
        }
        if (this.state.PlaceOfConsultationQuery.length > 0) {
          return (
            elem.PlaceOfConsultation.toLowerCase() ===
            this.state.PlaceOfConsultationQuery
          );
        }
        return true;
      });
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <b>Terminverwaltung</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadAppointments}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>
        <div className="Appointments-Toolbar">
          <div className="Appointments-Toolbar-Filter-Area">
            <SearchDropdown
              label="CarePsych:"
              options={this.state.CarePsych}
              defaultValue={this.state.CarePsychQuery}
              onChange={(value) => {
                this.filter('CarePsychQuery', value);
              }}
            />
            <SearchDropdown
              label="Aufbewahrungsort der Falldokumentation:"
              options={this.state.PlaceOfConsultation}
              defaultValue={this.state.PlaceOfConsultationQuery}
              onChange={(value) => {
                this.filter('PlaceOfConsultationQuery', value);
              }}
            />
          </div>
        </div>

        <div className="Page-Content">
          <ReactTable
            getTrProps={(_, val) => {
              return {
                onClick: () => {
                  return this.props.history.push(
                    `/clientmanagement/detail/appointments/detail/${val?.original?.Guid}/${val?.original?.ClientGuid}`,
                  );
                },
              };
            }}
            defaultSorted={[
              {
                id: 'DateOfAppointment',
                desc: true,
              },
            ]}
            data={data}
            pageSize={data.length}
            noDataText="Es wurden keine Termine gefunden."
            columns={[
              {
                accessor: 'DateOfAppointment',
                Header: 'Datum der Beratung',
                Cell: ({ original }) => {
                  return (
                    <>
                      {moment(original.DateOfAppointment).format('DD.MM.YYYY')}
                    </>
                  );
                },
              },
              {
                accessor: 'TimeOfAppointment',
                Header: 'Uhrzeit',
                Cell: ({ original }) => {
                  return <>{original.TimeOfAppointment}</>;
                },
              },
              {
                accessor: 'PlaceOfConsultation',
                Header: 'Beratungsort',
                Cell: ({ original }) => {
                  return (
                    <>
                      {
                        this.state.PlaceOfConsultation.find((elem) => {
                          return elem.value === original.PlaceOfConsultation;
                        })?.label
                      }
                    </>
                  );
                },
              },
              {
                accessor: 'CarePsych',
                Header: 'CARE-Psych.',
                Cell: ({ original }) => {
                  return (
                    <>
                      {
                        this.state.CarePsych.find((elem) => {
                          return elem.value === original.CarePsych;
                        })?.label
                      }
                    </>
                  );
                },
              },
              {
                accessor: 'Link',
                Header: 'Zur Detailansicht',
                Cell: () => {
                  return (
                    <>
                      <u>Hier klicken</u>&nbsp;
                      <FontAwesomeIcon icon={faEdit} />
                    </>
                  );
                },
              },
            ]}
          />
        </div>
      </>
    );
  }
}

export default withRouter(OverviewResubmission);
