import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import {
  faCalendarPlus,
  faSave,
  faTrashAlt,
  faFolderPlus,
  faFileSearch,
  faRedo,
  faArrowLeft,
  faCommentAltEdit,
  faFolderTimes,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../settings';
import DataContainer from '../../../../logic/dataContainer';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderbarButton from '../../../../components/HeaderbarButton';
import ClientForm from '../ClientForm';
import api from '../../../../logic/api';

class ClientDetail extends Component {
  dataContainer = new DataContainer();
  constructor(props) {
    super(props);

    this.clientGuid = props.match.params.clientGuid;
    this.logHistory = props.match.params.log;
    this.parentRoute = this.props.match.url.replace(
      new RegExp('/detail/.*'),
      '/detail',
    );
    this.userGuid = JSON.parse(localStorage.getItem('userdata')).userGuid;

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const data = { guid: this.clientGuid };
    if (this.logHistory === 'log') {
      data.isExtern = true;
      data.action = 'Auskunft an Klient/in erteilt';
    }

    api('get', settings.clientmanagement.dataContainer.get, data)
      .then((res) => {
        const entry = new DataContainer(res).get('Entry');
        this.dataContainer.add('Client', entry);
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    const oneDataContainer = JSON.stringify({
      ParamName: 'Client',
      Params: this.dataContainer.get('Client'),
    });

    api('POST', settings.clientmanagement.dataContainer.save, {
      container: oneDataContainer,
      action: 'Datensatz bearbeitet',
    })
      .then(() => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
      });
  };

  remove = async () => {
    swal({
      title: 'Löschen?',
      buttons: {
        cancle: 'abbrechen',
        delete: 'löschen',
      },
    }).then((value) => {
      if (value === 'delete') {
        api('POST', settings.clientmanagement.dataContainer.remove, {
          guid: this.clientGuid,
          action: 'Klient/in löschen',
          clientGuid: this.clientGuid,
        })
          .then(() => {
            swal({
              title: 'Gelöscht!',
              text: 'Erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.goBack();
          })
          .catch(() => {
            swal({
              title: 'Löschen nicht möglich!',
              text: 'Es ist ein Fehler beim löschen aufgetreten',
              icon: 'error',
            });
          });
      }
    });
  };

  closeCase = () => {
    swal({
      title: 'Fall abschließen?',
      buttons: {
        cancle: 'abbrechen',
        close: 'abschließen',
      },
    }).then((value) => {
      if (value === 'close') {
        this.closeCaseConfirmed();
      }
    });
  };

  closeCaseConfirmed = () => {
    this.setState({ loading: true });

    api('POST', settings.clientmanagement.client.setCaseDone, {
      ClientGuid: this.clientGuid,
      action: 'Wiedervorlage zum Löschen des Klient/in angelegt.',
    })
      .then(() => {
        this.setState({ loading: false });
        swal({
          title: 'Fall abgeschlossen!',
          icon: 'success',
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        swal({
          title: 'Abschließen nicht möglich!',
          text: 'Es ist ein Fehler beim Fall abschließen aufgetreten',
          icon: '',
        });
      });
  };

  render() {
    if (this.state.loading || !this.dataContainer) {
      return <Loader />;
    }

    if (!this.dataContainer.get('Client', 'Guid')) {
      return (
        <>
          <div className="Headerbar sticky">
            <div className="Headerbar-breadcrumbs">
              <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
              <b>Bearbeiten</b>
            </div>
            <div className="Headerbar-buttons"></div>
          </div>

          <div className="Split-Panes-Menu">
            <div className="Split-Pane-Menu">
              <div className="Split-Pane-Menu-Nav">
                <NavLink className="FormArea-Body-Tab" to="/">
                  <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
                </NavLink>
                <NavLink
                  to={this.props.match.url}
                  className="FormArea-Body-Tab"
                  activeClassName="is-Active"
                >
                  Grunddaten
                </NavLink>
              </div>
            </div>
            <div className="Split-Pane-Main">
              <div className="Page-Content">
                <div className="Buttonbar"></div>
                <div>Klient/in wurde gelöscht</div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <b>Bearbeiten</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faCalendarPlus}
              to={`${this.parentRoute}/appointments/new/${this.clientGuid}`}
            >
              Beratungstermin vereinbaren
            </HeaderbarButton>
            <HeaderbarButton
              icon={faRedo}
              to={`${this.parentRoute}/resubmission/new/${this.clientGuid}`}
            >
              Wiedervorlagen anlegen
            </HeaderbarButton>
            <HeaderbarButton
              icon={faFolderPlus}
              to={`${this.parentRoute}/caseFile/${this.clientGuid}`}
            >
              Falldokumentation erstellen
            </HeaderbarButton>
            <HeaderbarButton
              icon={faFileSearch}
              to={`${
                this.parentRoute
              }/selectCounselling/SelectAmbulatoryPsychotherapyOverview/${
                this.clientGuid
              }/${this.dataContainer.get('Client', 'LastName')}`}
            >
              Therapeut/in auswählen oder suchen
            </HeaderbarButton>
            {this.dataContainer.get('Client', 'IsDone') ? (
              <HeaderbarButton icon={faCheck}>
                Fall abgeschlossen
              </HeaderbarButton>
            ) : null}
            <HeaderbarButton
              icon={faCommentAltEdit}
              to={`${this.parentRoute}/caseStatistics/new/${this.clientGuid}`}
            >
              Fallstatistik
            </HeaderbarButton>
            <HeaderbarButton trash icon={faTrashAlt} onClick={this.remove}>
              löschen
            </HeaderbarButton>
          </div>
        </div>

        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to="/">
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
              </NavLink>
              <NavLink
                to={this.props.match.url}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Grunddaten
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/appointments/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Terminverwaltung
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/resubmission/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Wiedervorlage
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/caseStatistics/new/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Statistik
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/history/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Historie
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button type="primary" icon={faSave} onClick={this.save}>
                  Speichern
                </Button>
              </div>
              <ClientForm
                ref={(node) => {
                  return (this.form = node);
                }}
                dataContainer={this.dataContainer}
                onSaveChange={this.save}
              />
              <div className="Buttonbar-bottom">
                <Button type="primary" icon={faSave} onClick={this.save}>
                  Speichern
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientDetail;
