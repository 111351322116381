import PropTypes from 'prop-types';
import React, { Component } from 'react';
import reqwest from 'reqwest';
import settings from '../../../../settings';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import ContentBox from '../../../components/FormArea/ContentBox';
import Dropdown from '../../../components/Inputs/Dropdown';
import Input from '../../../components/Inputs/Input';
import Loader from '../../../components/Loader';
import Popup from '../../../components/Popup';
import getConstants from '../../../logic/constants';
import DataContainer from '../../../logic/dataContainer';

const newDataContainer = () => {
  const dataContainer = new DataContainer();
  dataContainer.add('Client', {
    // Guid: '', leve empty for new Clients
    Typ: 'Client',
    Chiffre: '',
    CaseStatus: '',
    FirstName: '',
    LastName: '',
    DateOfBirth: '',
    Gender: '',
    TelPrivate: '',
    TelMobile: '',
    TelBusiness: '',
    TelFavorite: '',
    MailToPatient: '',
    TelAvailabilityNotes: '',
    TelNotesOnAnsweringMachine: '',
    TelMayUserShowItself: '',
    EmailPrivate: '',
    MayUseEmail: '',
    AddrStreetAndNumber: '',
    AddrPostalCode: '',
    AddrCity: '',
    DeptName: '',
    DeptStreetAndNumber: '',
    DeptPostalCode: '',
    DutyStation: '',
    TypeOfBranchOrSchool: '',
    IsShift: 'n',
    IsManagementPosition: 'n',
    IsTemporaryDisability: 'n',

    EmploymentState: '',
    DateOfCivilServant: '',
    DateOfCollectiveBargainingAgreement: '',
    EmploymentScope: '',
    Supply: '',
    HealthInsurance: '',
    IsServiceUsedBefore: 'n',
    /** Beratungsanliegen */
    CounselingConcern: '',
    /** Sicherheitsfrage */
    Question: '',
    /** Sicherheitsantwort */
    Answer: '',
    /** Aufbewahrungsort der Falldokumentation  */
    PlaceOfStorage: '',
    /** Rechte Visible für bestimmte User */
    VisibleFor: '',
    /** Letzter Kontakt (might be redundant)*/
    LastContact: '',
    CountConsultations: 0,
    IsDone: 'false',
    IsCaseClosed: 'false',
  });
  return dataContainer;
};
const VERSORGUNG = {
  // Beihilfe und PKV
  PFV: '3936d0cd-f1c3-4a0b-b7a3-e7291b63ca4f',
  // Gesetzlich versichert
  GKV: '7184d2ea-6487-4cea-b86f-cb9ce6fcc50c',
  //  Gesetzlich freiwillig versichert
  GKV_Freiwillig: '12449159-33e8-4ce8-a374-fd2d2b647ffe',
};

class ClientForm extends Component {
  dataContainer;
  constructor(props) {
    super(props);

    this.state = {
      userDepartmentGuid: null,
      Department: [],
      Genders: [],
      PKV: [],
      GKV: [],
      EmploymentState: [],
      DateOfCivilServant: [],
      DateOfCollectiveBargainingAgreement: [],
      EmploymentScope: [],
      Supply: [],
      TypeOfBranchOrSchoolMI: [],
      TypeOfBranchOrSchoolMK: [],
      PlaceOfStorage: [],
      healthInsuranceOptions: [],
      CaseStatus: [],
      openCaseStatusPopup: false,
    };
    this.dataContainer = props.dataContainer
      ? props.dataContainer
      : newDataContainer();

    this.onSaveChange = props.onSaveChange;
  }
  componentDidMount() {
    this.loadUserDepartment();
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      Department: await getConstants('Department'),
      Gender: await getConstants('Gender'),
      PKV: await getConstants('PKV'),
      GKV: await getConstants('GKV'),
      EmploymentState: await getConstants('EmploymentState'),
      DateOfCivilServant: await getConstants('DateOfCivilServant'),
      DateOfCollectiveBargainingAgreement: await getConstants(
        'DateOfCollectiveBargainingAgreement',
      ),
      EmploymentScope: await getConstants('EmploymentScope'),
      Supply: await getConstants('Supply'),
      TypeOfBranchOrSchoolMI: await getConstants('TypeOfBranchOrSchoolMI'),
      TypeOfBranchOrSchoolMK: await getConstants('TypeOfBranchOrSchoolMK'),
      PlaceOfStorage: await getConstants('PlaceOfStorage'),
      healthInsuranceOptions: [].concat(
        await getConstants('PKV'),
        await getConstants('GKV'),
      ),
      CaseStatus: await getConstants('CaseStatus'),
    });
  }

  async loadUserDepartment() {
    reqwest({
      method: 'get',
      url: settings.usermanagement.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.userGuid,
      },
    }).then((res) => {
      const userDataContainer = new DataContainer(res);
      const depGuid = userDataContainer.get('Workdata', 'Department');
      this.setState({
        loadingUser: false,
        userDepartmentGuid: depGuid,
      });
    });
  }

  getDepartment = () => {
    if (this.state.userDepartmentGuid && this.state.Department) {
      const department = this.state.Department.find((dep) => {
        return dep.value === this.state.userDepartmentGuid;
      })?.kkz;
      return department;
    } else {
      return null;
    }
  };

  validate = async () => {
    return this.form.validate();
  };

  getHealthInsuranceOptions = () => {
    const supply = this.dataContainer.get('Client', 'Supply');
    let healthInsuranceOptions = [];

    if (supply === VERSORGUNG.PFV) {
      healthInsuranceOptions = this.state.PKV;
    } else if (
      supply === VERSORGUNG.GKV ||
      supply === VERSORGUNG.GKV_Freiwillig
    ) {
      healthInsuranceOptions = this.state.GKV;
    }

    this.setState({
      healthInsuranceOptions,
    });
  };

  isCaseClosed = () =>
    this.dataContainer.get('Client', 'IsCaseClosed') === true ? true : false;

  handleCaseStatusChange = (value) => {
    const closedCases = this.state.CaseStatus.filter(
      (status) => status.kkz === 'Erfolgt' || status.kkz === 'Löschen',
    );

    if (closedCases.find((status) => status.value === value)) {
      this.dataContainer.set('Client', 'IsCaseClosed')(true);
    } else {
      this.dataContainer.set('Client', 'IsCaseClosed')(false);
    }
  };

  reopenCase = async () => {
    const res = await reqwest({
      method: 'post',
      url: settings.clientmanagement.client.reopenCase,
      data: {
        ClientGuid: this.dataContainer.get('Client', 'Guid'),
        newCaseStatus: this.dataContainer.get('Client', 'CaseStatus'),
      },
    });

    if (res) {
      this.setState({ openCaseStatusPopup: false });
      this.onSaveChange();
    }
  };

  render() {
    if (!this.state.Department) {
      return <Loader />;
    }

    return (
      <Form
        ref={(node) => {
          return (this.form = node);
        }}
      >
        {this.state.openCaseStatusPopup && (
          <Popup>
            <h2>Fallstaus wieder eröffnen</h2>
            <Dropdown
              label="Fallstatus:"
              getSelected={(value) => {
                this.handleCaseStatusChange(value);
                this.dataContainer.set('Client', 'CaseStatus')(value);
              }}
              options={this.state.CaseStatus}
              name="CaseStatus"
              fullWidth
            />
            <div className="button-container">
              <button className="button-yes" onClick={this.reopenCase}>
                Bestätigen
              </button>
              <button
                className="button-no"
                onClick={() => this.setState({ openCaseStatusPopup: false })}
              >
                Abbrechen
              </button>
            </div>
          </Popup>
        )}

        <div className="Split-Panes">
          <div className="Split-Pane">
            <ContentBox title="Stammdaten">
              <Input
                type="text"
                validator={[]}
                onChange={(value) => {
                  this.dataContainer.set('Client', 'Chiffre')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'Chiffre')}
                label="Chiffre:"
                name="Chiffre"
              />
              <div className="Input-wrapper spaceer-dummy"></div>
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Client', 'FirstName')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'FirstName')}
                label="Vorname:"
                name="FirstName"
              />
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Client', 'LastName')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'LastName')}
                label="Familienname:"
                name="LastName"
              />
              <Dropdown
                label="Geschlecht:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'Gender')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'Gender')}
                options={this.state.Gender}
                validator={[]}
                name="Gender"
              />
              <Input
                type="date"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'DateOfBirth')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'DateOfBirth')}
                label="Geburtsdatum:"
                name="DateOfBirth"
              />
            </ContentBox>
            <ContentBox title="Telefon">
              <Input
                type="tel"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'TelPrivate')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'TelPrivate')}
                label="privat:"
                name="TelPrivate"
              />
              <Input
                type="tel"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'TelMobile')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'TelMobile')}
                label="mobil:"
                name="TelMobile"
              />
              <Input
                type="tel"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'TelBusiness')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'TelBusiness')}
                label="dienstlich:"
                name="TelBusiness"
              />
              <Dropdown
                label="Bevorzugte Telefonnummer:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'TelFavorite')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'TelFavorite')}
                options={[
                  {
                    value: 'private',
                    label: 'privat',
                  },
                  {
                    value: 'mobile',
                    label: 'mobil',
                  },
                  {
                    value: 'buisness',
                    label: 'dienstlich',
                  },
                ]}
                validator={[]}
                name="TelFavorite"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'TelAvailabilityNotes',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'TelAvailabilityNotes',
                )}
                label="Hinweis zur Erreichbarkeit:"
                name="TelAvailabilityNotes"
              />
              <Dropdown
                label="Benachrichtigung auf Anrufbeantworter:"
                getSelected={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'TelNotesOnAnsweringMachine',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'TelNotesOnAnsweringMachine',
                )}
                options={[
                  {
                    value: 'y',
                    label: 'Ja',
                  },
                  {
                    value: 'n',
                    label: 'Nein',
                  },
                ]}
                validator={[]}
                name="TelNotesOnAnsweringMachine"
              />
              <Dropdown
                label="CARE-Beratung darf sich zu erkennen geben:"
                getSelected={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'TelMayUserShowItself',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'TelMayUserShowItself',
                )}
                options={[
                  {
                    value: 'y',
                    label: 'Ja',
                  },
                  {
                    value: 'n',
                    label: 'Nein',
                  },
                ]}
                validator={[]}
                name="TelMayUserShowItself"
              />
            </ContentBox>
            <ContentBox title="E-Mail">
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'EmailPrivate')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'EmailPrivate')}
                label="Private E-Mail Adresse:"
                name="EmailPrivate"
              />
              <Dropdown
                label="Einverständnis zur Nutzung der privaten Mailadresse:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'MayUseEmail')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'MayUseEmail')}
                options={[
                  {
                    value: 'y',
                    label: 'Ja',
                  },
                  {
                    value: 'n',
                    label: 'Nein',
                  },
                ]}
                validator={[]}
                name="MayUseEmail"
              />
            </ContentBox>
            <ContentBox title="Adresse privat">
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'AddrStreetAndNumber',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'AddrStreetAndNumber',
                )}
                label="Straße und Hausnummer:"
                name="AddrStreetAndNumber"
                fullWidth
              />
              <Input
                type="text"
                validator={[
                  {
                    custom: (value) => {
                      return (
                        !value ||
                        value.length === 0 ||
                        new RegExp(
                          /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
                        ).test(value)
                      );
                    },
                    error: 'Diese Postleitzahl hat ein falsches Format.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Client', 'AddrPostalCode')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'AddrPostalCode',
                )}
                label="Postleitzahl:"
                name="AddrPostalCode"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'AddrCity')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'AddrCity')}
                label="Wohnort:"
                name="AddrCity"
              />
            </ContentBox>
            <ContentBox title="Dienststelle des Klienten">
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'DeptName')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'DeptName')}
                label="Name der Dienststelle / Schule:"
                name="DeptName"
                fullWidth
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'DeptStreetAndNumber',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'DeptStreetAndNumber',
                )}
                label="Straße und Hausnummer:"
                name="DeptStreetAndNumber"
                fullWidth
              />
              <Input
                type="text"
                validator={[
                  {
                    custom: (value) => {
                      return (
                        !value ||
                        value.length === 0 ||
                        new RegExp(
                          /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
                        ).test(value)
                      );
                    },
                    error: 'Diese Postleitzahl hat ein falsches Format.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('Client', 'DeptPostalCode')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'DeptPostalCode',
                )}
                label="Postleitzahl:"
                name="DeptPostalCode"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'DutyStation')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'DutyStation')}
                label="Dienstort:"
                name="DutyStation"
              />
              <div className="Input-wrapper spaceer-dummy"></div>
              {this.getDepartment() === 'MI' && (
                <Dropdown
                  label="Verwaltungszweig (MI):"
                  getSelected={(value) => {
                    this.dataContainer.set(
                      'Client',
                      'TypeOfBranchOrSchool',
                    )(value);
                  }}
                  defaultValue={this.dataContainer.get(
                    'Client',
                    'TypeOfBranchOrSchool',
                  )}
                  options={this.state.TypeOfBranchOrSchoolMI}
                  validator={[]}
                  name="TypeOfBranchOrSchool"
                />
              )}
              {this.getDepartment() === 'MK' && (
                <Dropdown
                  label="Schulform (MK):"
                  getSelected={(value) => {
                    this.dataContainer.set(
                      'Client',
                      'TypeOfBranchOrSchool',
                    )(value);
                  }}
                  defaultValue={this.dataContainer.get(
                    'Client',
                    'TypeOfBranchOrSchool',
                  )}
                  options={this.state.TypeOfBranchOrSchoolMK}
                  validator={[]}
                  name="TypeOfBranchOrSchool"
                />
              )}
            </ContentBox>
            <ContentBox title="Besonderheiten">
              <Dropdown
                label="Schichtdienst:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'IsShift')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'IsShift')}
                options={[
                  {
                    value: 'y',
                    label: 'Ja',
                  },
                  {
                    value: 'n',
                    label: 'Nein',
                  },
                ]}
                validator={[]}
                name="IsShift"
              />
              <Dropdown
                label="Führungsfunktion:"
                getSelected={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'IsManagementPosition',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'IsManagementPosition',
                )}
                options={[
                  {
                    value: 'y',
                    label: 'Ja',
                  },
                  {
                    value: 'n',
                    label: 'Nein',
                  },
                ]}
                validator={[]}
                name="IsManagementPosition"
              />
              <Dropdown
                label="Arbeitsunfähig bei Kontaktaufnahme:"
                getSelected={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'IsTemporaryDisability',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'IsTemporaryDisability',
                )}
                options={[
                  {
                    value: 'y',
                    label: 'Ja',
                  },
                  {
                    value: 'n',
                    label: 'Nein',
                  },
                ]}
                validator={[]}
                name="IsTemporaryDisability"
              />
              <Input
                type="number"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'LevelOfDisability')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'LevelOfDisability',
                )}
                label="Grad der Behinderung:"
                name="LevelOfDisability"
              />
              <div className="Input-wrapper spaceer-dummy"></div>
              <Dropdown
                label="Beschäftigungsstatus:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'EmploymentState')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'EmploymentState',
                )}
                options={this.state.EmploymentState}
                validator={[]}
                name="EmploymentState"
              />
              <div className="Input-wrapper spaceer-dummy"></div>
              <Dropdown
                label="Wenn verbeamtet:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'DateOfCivilServant')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'DateOfCivilServant',
                )}
                options={this.state.DateOfCivilServant}
                validator={[]}
                name="DateOfCivilServant"
              />
              <Dropdown
                label="Wenn tarifbeschäftigt:"
                getSelected={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'DateOfCollectiveBargainingAgreement',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'DateOfCollectiveBargainingAgreement',
                )}
                options={this.state.DateOfCollectiveBargainingAgreement}
                validator={[]}
                name="DateOfCollectiveBargainingAgreement"
              />
              <Dropdown
                label="Beschäftigungsumfang:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'EmploymentScope')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'EmploymentScope',
                )}
                options={this.state.EmploymentScope}
                validator={[]}
                name="EmploymentScope"
              />
              <Dropdown
                label="Kostenträger:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'Supply')(value);
                  this.getHealthInsuranceOptions();
                }}
                defaultValue={this.dataContainer.get('Client', 'Supply')}
                options={this.state.Supply}
                validator={[]}
                name="Supply"
              />

              <Dropdown
                label="Krankenversicherung:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'HealthInsurance')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'HealthInsurance',
                )}
                options={this.state.healthInsuranceOptions}
                validator={[]}
                name="HealthInsurance"
              />

              <Dropdown
                label="Vorherige Inanspruchnahme von CARE:"
                getSelected={(value) => {
                  this.dataContainer.set(
                    'Client',
                    'IsServiceUsedBefore',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'IsServiceUsedBefore',
                )}
                options={[
                  {
                    value: 'y',
                    label: 'Ja',
                  },
                  {
                    value: 'n',
                    label: 'Nein',
                  },
                ]}
                validator={[]}
                name="IsServiceUsedBefore"
              />
            </ContentBox>
            <ContentBox title="Informationen zur Verwaltung">
              <Dropdown
                label="Aufbewahrungsort der Falldokumentation:"
                getSelected={(value) => {
                  this.dataContainer.set('Client', 'PlaceOfStorage')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'PlaceOfStorage',
                )}
                options={this.state.PlaceOfStorage}
                name="PlaceOfStorage"
              />
            </ContentBox>
          </div>
          <div className="Split-Pane">
            <ContentBox title="Fallstatus">
              {this.isCaseClosed() && (
                <div className="Absent-Info">
                  <div className="Label">
                    <Button
                      onClick={() =>
                        this.setState({ openCaseStatusPopup: true })
                      }
                    >
                      Fall wieder eröffnen?
                    </Button>
                  </div>
                </div>
              )}
              <Dropdown
                label="Fallstatus:"
                getSelected={(value) => {
                  this.handleCaseStatusChange(value);
                  this.dataContainer.set('Client', 'CaseStatus')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'CaseStatus')}
                options={this.state.CaseStatus}
                name="CaseStatus"
                isDisabled={this.isCaseClosed()}
              />
            </ContentBox>
            <ContentBox title="Hinweise zur Fallberatung">
              <Input
                className="counselin-concern"
                type="textarea"
                placeholder={
                  this.dataContainer.get('Entry', 'IsDone')
                    ? 'Fall ist bereits Abgeschlossen!'
                    : '...'
                }
                isDisabled={
                  this.dataContainer.get('Entry', 'IsDone') ? true : false
                }
                validator={[]}
                onChange={(value) => {
                  this.dataContainer.set('Client', 'CounselingConcern')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'Client',
                  'CounselingConcern',
                )}
                label=""
                name="convern"
                fullWidth
              />
            </ContentBox>
            <ContentBox title="Sicherheitsfrage">
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'Question')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'Question')}
                validator={[
                  {
                    required: true,
                    error: 'Sicherheitsfrage ist nicht ausgefüllt.',
                  },
                ]}
                label="Sicherheitsfrage:"
                name="Question"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('Client', 'Answer')(value);
                }}
                defaultValue={this.dataContainer.get('Client', 'Answer')}
                validator={[
                  {
                    required: true,
                    error: 'Antwort ist nicht ausgefüllt.',
                  },
                ]}
                label="Antwort:"
                name="Answer"
              />
            </ContentBox>
          </div>
        </div>
      </Form>
    );
  }
}

ClientForm.propTypes = {
  dataContainer: PropTypes.shape(),
  isDisabled: PropTypes.bool,
};

ClientForm.defaultProps = {
  dataContainer: null,
  isDisabled: false,
};

export default ClientForm;
